@import './../../base';
@import '~bulma/sass/base/animations';
@import '~bulma/sass/elements/content';

@include touch {
  // Make the margin less on mobile devices.
  #weather-section {
    padding: 3rem 1rem;
  }
}

td, th {
  white-space: nowrap;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $grey-lighter;
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 2em;
  position: relative;
  width: 2em;
}

.is-text-danger {
  color: $danger;
}

.is-text-info {
  color: $info;
}